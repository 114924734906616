























































import './styles/WorkListHeader.scss';
import './styles/WorkListHeaderAdaptive.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { WorkListMutations } from '@store/modules/work-list/Types';
import { IWorkListPopupsState } from '@store/modules/work-list/modules/popups/Interfaces';
import { PopupActions } from '@store/Popup';
import { ITogglePopupPayload } from '@/interfaces/ITogglePopupPayload';
import { IProject } from '@store/modules/project/Interfaces';
import { IConstruct, IWork, IWorkListState } from "@store/modules/work-list/interfaces/Interfaces";

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSWorkList = namespace('storeWorkList');
const NSWorkListPopups = namespace('storeWorkList/storeWorkListPopups');

@Component({
    name: 'WorkListHeader',

    components: {
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
        ButtonFavorite: () => import('@components/buttons/ButtonFavorite.vue'),
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
        BaseViewToggler: () => import('@components/BaseViewToggler'),
        PopupFiltersWorkList: () => import('@components/popups/PopupFiltersWorkList.vue'),
        FiltersPreview: () => import('@components/FiltersPreview/FiltersPreview.vue'),
    },
})
export default class WorkListHeader extends Vue {
    $refs!: {
        filterPopup: HTMLFormElement,
        filtersPreview: HTMLFormElement,
    }

    @Prop({}) constructsToDisplay!: any[];

    @NSWorkList.State((state: IWorkListState) => state.viewingPack) viewingPack!: IConstruct[];
    @NSWorkList.State((state: IWorkListState) => state.viewingWork) viewingWork!: IWork;
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSWorkList.Getter('constructsGrouping') constructsGrouping!: 'composite' | 'packs';
    @NSWorkListPopups.Getter('workAddingPopupToggler') workAddingPopupToggler!: boolean;
    @NSWorkListPopups.Getter('deleteWorkPopupToggler') deleteWorkPopupToggler!: boolean;
    @NSUser.Getter('userRightOnlyGSN') userRightOnlyGSN!: boolean;
    @NSWorkList.Mutation(WorkListMutations.M_SWITCH_CONSTRUCTS_GROUPING)
    switchGrouping!: (groupingType: 'composite' | 'packs') => void;
    @NSWorkListPopups.Action(PopupActions.A_TOGGLE_POPUP)
    togglePopup!: (
        payload: ITogglePopupPayload<IWorkListPopupsState>,
    ) => void;

    views: any[] = [
        {
            label: 'Структура работ',
            value: 'composite',
        },{
            label: 'Проектная документация',
            value: 'packs',
        },
    ];

    mounted() {
        this.resetFilters();
    }

    switchGroupingView(view): void {
        this.switchGrouping(view.value);
        this.closePopupFiltersForEmptyList();
    }

    closePopupFiltersForEmptyList(): void {
        if ((this.constructsGrouping === 'packs' && !this.viewingPack?.length)
            || (this.constructsGrouping === 'composite' && !this.viewingWork.constructs?.length)) {
            if (this.$refs.filterPopup) {
                this.$refs.filterPopup.closePopup();
            }
        }
    }

    dispatchTogglePopupAction(toggler: Partial<keyof IWorkListPopupsState>, state: boolean) {
        this.togglePopup({
            popupToggler: toggler,
            state,
        });
    }

    openFilters() {
        this.$refs.filterPopup.openPopup();
    }

    resetFilters() {
        if (this.$refs.filterPopup) {
            this.$refs.filterPopup.cancelFilters();
        }
    }

    setFiltersPreview(filtersData) {
        this.$refs.filtersPreview.setFiltersData(filtersData);
    }

    resetFiltersByPreview(data) {
        this.$refs.filterPopup.changeSelected(data, true);
    }

    resetFiltersDateByPreview(data) {
        this.$refs.filterPopup.changeSelected(data, true);
    }
}
